/* eslint-disable max-len */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const navigation: FuseNavigationItem[] = [
    {
        id: "operacoes",
        title: "Operações",
        type: "basic",
        icon: "heroicons_outline:currency-dollar",
        link: "/operacoes",
        role: {
            OPERACAO: ["READ"],
        },
    },
    {
        id: "clientes",
        title: "Cedentes",
        type: "basic",
        icon: "heroicons_outline:briefcase",
        link: "/clientes",
        role: {
            CEDENTE: ["READ"],
            MINIBANCO: ["READ"],
        },
    },
    {
        id: "payers",
        title: "Sacados",
        type: "basic",
        icon: "heroicons_outline:document-duplicate",
        link: "/payers",
        role: {
            SACADO: ["READ"],
        },
    },
    {
        id: "titulos",
        title: "Recebíveis",
        type: "basic",
        icon: "heroicons_outline:document-text",
        link: "/titulos",
        role: {
            RECEBIVEL: ["READ"],
            MINIBANCO: ["READ"],
        },
    },
    {
        type: "group",
        title: "Administrativo",
        icon: "heroicons_outline:clipboard-list",
        children: [
            {
                id: "minibancos",
                title: "Minibancos",
                type: "basic",
                icon: "heroicons_outline:library",
                link: "/minibanco",
                role: {
                    MINIBANCO: ["READ"],
                },
            },

            {
                id: "empresas",
                title: "Empresas",
                type: "basic",
                icon: "heroicons_outline:office-building",
                link: "/empresa",
                role: {
                    EMPRESA: ["READ"],
                },
            },
            {
                id: "users",
                title: "Usuários",
                type: "basic",
                icon: "heroicons_outline:users",
                link: "/usuario",
                role: {
                    USUARIO: ["READ"],
                },
            },
        ],
    },
    {
        id: "calculadora",
        title: "Calcula-me",
        type: "basic",
        icon: "heroicons_outline:calculator",
        link: "/calcula-me",
    },

    // {
    //   id: "partners",
    //   title: "Parceiros",
    //   type: "basic",
    //   icon: "heroicons_outline:user-group",
    //   link: "/parceiros",
    // },
];
